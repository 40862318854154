<template>
    <div v-loading="$wait.is('loading')" class="card w-full employee-information" element-loading-spinner="el-custom-spinner">
        <ModalChangePassword
            v-if="modalChangePasswordVisible"
            :modalChangePassword="modalChangePassword"
            @close="modalChangePassword = false"
            @closed="modalChangePasswordVisible = false"
        />

        <header class="border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('employees.employee_information') }}</h1>
        </header>

        <section>
            <el-form :model="employeeData" :rules="validationRules" label-width="200px">
                <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                    <el-col :span="13" :offset="3">
                        <el-form-item :label="$t('employees.name')">
                            <el-input v-model="employeeData.name" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.surname')">
                            <el-input v-model="employeeData.surname" class="pl-3" />
                        </el-form-item>
                        <el-form-item label="Personummer">
                            <el-input v-model="employeeData.personnummer" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.first_day_at_work')">
                            <el-date-picker
                                v-model="employeeData.started_working_at"
                                :editable="false"
                                :clearable="false"
                                format="yyyy.MM.dd"
                                value-format="dd.MM.yyyy"
                                :picker-options="{ firstDayOfWeek: 1 }"
                                type="date"
                                class="ml-3 pr-3"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                    <el-col :span="13" :offset="3">
                        <el-form-item :label="$t('employees.telephone_number')">
                            <el-input v-model="employeeData.telephone_number" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.email_login_as_well')">
                            <el-input v-model="employeeData.email" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.assign_to_manager')">
                            <el-select v-model="employeeData.manager_id" clearable class="pl-3 w-full">
                                <el-option
                                    v-for="manager in managers"
                                    :key="manager.id"
                                    :value="manager.id"
                                    :label="manager.name + ' ' + manager.surname"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                    <el-col :span="13" :offset="3">
                        <el-form-item :label="$t('employees.working_hours_week')" prop="working_hours_week" class="pr-3">
                            <el-input-number v-model="employeeData.working_hours_week" :min="0" :max="80" class="ml-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.bid_per_hour')" class="pr-3">
                            <money v-model="employeeData.bid_per_hour" class="el-input__inner ml-3 money_input el-input--small" v-bind="money" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.account_number')">
                            <el-input v-model="employeeData.account_number" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.clearing_number')">
                            <el-input v-model="employeeData.clearing_number" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.contract_type')" prop="contract_type">
                            <el-select v-model="employeeData.contract_type" class="pl-3 w-full">
                                <el-option value="" :label="$t('employees.none')" />
                                <el-option value="Provanställning" label="Provanställning" />
                                <el-option value="Tillsvidareanställning" label="Tillsvidareanställning" />
                                <el-option value="Vikarieanställning" label="Vikarieanställning" />
                                <el-option value="Visstidsanställning" label="Visstidsanställning" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                    <el-col :span="13" :offset="3">
                        <el-form-item :label="$t('employees.note')">
                            <div class="pl-4">
                                <quill-editor
                                    ref="myQuillEditor"
                                    v-model="employeeData.note"
                                    :options="editorOption"
                                />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15">
                    <el-col :span="13" :offset="3" class="justify-end flex">
                        <el-button type="warning" @click="changePasswordModal">
                            {{ $t('employees.change_password') }}
                        </el-button>
                        <el-button type="success" :loading="$wait.is('updating')" @click="update">
                            {{ $t('common.update') }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </section>
    </div>
</template>
<script>
// @Filip
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import Vue from 'vue';

import { Money } from 'v-money';
import { quillEditor } from 'vue-quill-editor';

Vue.use(Money);

export default {
    components: {
        ModalChangePassword: () => import(/* webpackChunkName: "EmployeeInformation/ModalChangePassword" */ './components/ModalChangePassword'),
        Money,
        quillEditor,
    },

    data() {
        return {
            employeeData:               {},
            validationRules:            {},
            employeeUuid:               this.$route.params.uuid,
            managers:                   [],
            modalChangePasswordVisible: false,
            modalChangePassword:        false,
            money:                      {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            editorOption: {
                placeholder: ' ',
                debug:       false,
                modules:     {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                    ],
                },
            },
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'general');
    },

    created() {
        this.getManagers();
        this.getDetails();
    },

    methods: {
        async getManagers() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers`);
            this.managers = data;
        },

        async getDetails() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}`);
            this.employeeData = data;

            this.$wait.end('loading');
        },

        async update() {
            this.$wait.start('updating');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}`, {
                name:               this.employeeData.name,
                surname:            this.employeeData.surname,
                personnummer:       this.employeeData.personnummer,
                telephone_number:   this.employeeData.telephone_number,
                email:              this.employeeData.email,
                manager_id:         this.employeeData.manager_id,
                started_working_at: this.$dayjs(this.employeeData.started_working_at, 'DD.MM.YYYY').unix(),
                working_hours_week: this.employeeData.working_hours_week,
                bid_per_hour:       this.employeeData.bid_per_hour.replace(',', ''),
                account_number:     this.employeeData.account_number,
                clearing_number:    this.employeeData.clearing_number,
                contract_type:      this.employeeData.contract_type,
                note:               this.employeeData.note,
            });

            this.$notify.success({ title: 'Success' });
            this.$wait.end('updating');
        },

        async changePasswordModal() {
            this.modalChangePasswordVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalChangePassword = true;
        },
    },
};
</script>
<style>
.employee-information .money_input {
    padding-right: 50px !important;
    height: 32px !important;
}
.employee-information .quill-editor .ql-toolbar{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: #ddd;
}
.employee-information .quill-editor .ql-container{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #ddd;
}
</style>
